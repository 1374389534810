// Packages
import React, { useEffect, useState, useContext } from 'react';

// Local 
// import { fakeAuthProvider } from './fakeauth';

let DetailedContext = React.createContext(null);

export function useDetailed() {
    return useContext(DetailedContext);
}

export function DetailedProvider({ ...props }) {
    // let lsKey = 'gdc.user';
    let [state, setState] = useState(false);

    useEffect(() => {
        // const lsUser = getLSUser();
        // if (lsUser) {
        //     setUser(lsUser);
        // }
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    let toggle = () => {
        setState(!state);
    }

    let value = { state, toggle };

    return <DetailedContext.Provider value={value}>{props.children}</DetailedContext.Provider>;
}
