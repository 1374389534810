// Packages
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

// Auth
import { useAuth } from 'components/auth/auth';

export default function LoginPage({ ...props }) {
    let navigate = useNavigate();
    let auth = useAuth();

    useEffect(() => {
        auth.signout(() => navigate("/"));
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
           
        </>
    );
}