// Packages
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from "react-redux"
import cx from 'classnames';

// Redux
import { formState, getWaterfallOptions } from "redux/reducers/bv/bvSlice";

// Styles
import './bv-waterfall.scss';

export default function BVWaterfall({ ...props }) {
    const waterfallOptions = useSelector(getWaterfallOptions);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(waterfallOptions);
    }, [waterfallOptions]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="kyc-waterfall flex-vertical flex-grow">
            <div className="group-waterfall">
                <div className="group center">
                    <h3> Waterfall </h3>
                </div>
                {options && options.length > 0 ?
                    options.map((item, key) => {
                        return (<WaterfallItem key={key} index={key} item={item} />)
                    })
                    :
                    <> </>
                }
            </div>
        </div >
    )
}

function WaterfallItem({ index, item, ...props }) {
    const form = useSelector(formState);

    const location = useLocation();
    const [active, setActive] = useState(false);

    useEffect(() => {
        console.log('item', item);

        setActive(false);
    },[item])

    useEffect(() => {
        if (form.submitted) {
            setActive(true);
        }
        else {
            setActive(false);
        }

        if (form.submitting) {
            onLoad();
        }
    }, [form]);  // eslint-disable-line react-hooks/exhaustive-deps 

    function onLoad() {
        setTimeout(() => {
            setActive(true);
        }, (100 * (index + 1)))
    }

    function getMatchValue(match) {
        if (match === 'full') {
            return 'Full Match';
        }
        else if (match === 'partial') {
            return 'Partial Match';
        }
        else if (match === 'skip') {
            return 'Skipped';
        }
        else {
            return 'No Match';
        }
    }

    const classWaterfall = cx(
        'waterfall-item',
        item.match,
        { 'active': active },
    )

    return (
        <>
            <div className={classWaterfall}>
                <div className="waterfall-bubble"> {item.name} </div>
                <div className="flex-grow"> </div>
                <h3 className="match-text"> {getMatchValue(item.match)} </h3>
            </div>
            <div className="matches">
                {item.matches && location.search === '?debug' ?
                    <>
                        {Object.keys(item.matches).map(function (key, index) {
                            return (<p> <b>{key}:</b> {JSON.stringify(item.matches[key])} </p>)
                        })
                        }
                    </>
                    : <> </>
                }
            </div>
        </>
    )
}