import cx from 'classnames';

import './result-icon.scss';

export default function ResultIcon({ score, ...props }) {

    const resultClass = cx(
        'result-icon',
        { 'pass': score === 10 },
        { 'partial': score === 20 },
        { 'none': score === 30 },
    )

    return (
        <div className={resultClass}>
            <div className="inner">
                {score}
            </div>
            <div className="inner-text">
                { score === 10 ? 'Full Match':  <> </> }
                { score === 20 ? 'Partial Match':  <> </> }
                { score === 30 ? 'No Match':  <> </> }
            </div>
        </div>
    )
}