// Local
import Router from 'components/router';
import Nav from 'components/nav';

// Styles
import './App.scss';
import { DetailedProvider } from 'helpers/useDetailed';

function App() {
 
  return (
    <div className="App">
      <DetailedProvider>
        <Nav />
        <Router />
      </DetailedProvider>
    </div>
  );
}

export default App;
