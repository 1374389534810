// Packages
import React, { useRef, useEffect, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { OrbitControls } from '@react-three/drei/core/OrbitControls';

export default function Controls({ target, position, onRotateFinish, ...props }) {
    const [lerping, setLerping] = useState(false);
    const [cameraAlpha, setAlpha] = useState(0);

    const {
        camera,
        gl: { domElement },
    } = useThree();
    const controls = useRef();

    useEffect(() => {
        camera.position.copy(position);
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!lerping) {
            setLerping(true);
            onLerpStart();
        }
    }, [position])  // eslint-disable-line react-hooks/exhaustive-deps



    function onLerpStart() {
        setAlpha(0);
        controls.current.enabled = false;
        if (camera.position.equals(position)) {
            onLerpComplete();
        }
    }

    const lerpCamera = (delta) => {
        // camera.position.lerp(position, cameraAlpha);

        const countryPos = new THREE.Vector3().copy(position);
        countryPos.normalize();
        countryPos.multiplyScalar(170);

        const length = new THREE.Vector3().copy(camera.position);
        length.normalize();

        if (position) {
            length.multiplyScalar(170);
        }

        camera.position.lerpVectors(camera.position, length, 0.2);
        camera.position.lerp(countryPos, cameraAlpha);

        if (cameraAlpha < 0.1) {
            let alpha = cameraAlpha + (0.05 * delta);
            setAlpha(alpha);
        }
        else {
            onLerpComplete();
        }
    };

    function onLerpComplete() {
        controls.current.enabled = true;

        if (onRotateFinish) {
            onRotateFinish();
        }
        setAlpha(0);
        setLerping(false);
    };


    useFrame(({ clock, camera }, delta) => {
        if (lerping) {
            lerpCamera(delta);
        }
        controls.current.update();
        controls.current.enableZoom = false;
    });


    return <OrbitControls ref={controls} args={[camera, domElement]} />;
}