// Packages
import cx from 'classnames';

// Styles
import './match-rule.scss';

export default function MatchRule({ item, ...props }) {
    const classMatchRule = cx(
        'match-rule',
        item.match,
    )
    return (
        <>
            <div className={classMatchRule}>
                <div className="match-name"> {item.name} </div>
                <div className="match-rule"> {item.matchRule} </div>
            </div>
        </>
    )
}