// Packages
import { useSelector, useDispatch } from "react-redux";

// Local
import Canvas from 'three/canvas';

// Redux 
import { formState, selectedOrigin, selectedCountry, setOrigin, getOrigins, getCountries } from "redux/reducers/bv/bvSlice";

// Styles 
import './bv-worldview.scss';
import { useEffect } from "react";

export default function BVWorldview({ ...props }) {
    const dispatch = useDispatch();
    const origins = useSelector(getOrigins);
    const origin = useSelector(selectedOrigin);


    const form = useSelector(formState);
    // const origin = useSelector(selectedOrigin);
    // const origins = useSelector(getOrigins);
    const country = useSelector(selectedCountry);
    const countries = useSelector(getCountries);



    console.log('origin', origin);

    useEffect(() => {
        if (origins && origins.length > 0) {
            const found = origins.find((item) => item.id === 69);
            dispatch(setOrigin(found));
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function handleOriginChange(event) {
        const value = Number(event?.target?.value);
        if (value !== -1) {
            if (origins && origins.length > 0) {
                const found = origins.find((item) => item.id === value);
                dispatch(setOrigin(found));
            }
        }
    }

    function getOriginOptions() {
        let options = [];
        if (origins) {
            for (let o in origins) {
                options.push(origins[o]);
            }
            options.sort(function (a, b) {
                return a.name > b.name ? 1 : -1;
            });
        }
        return options;
    }

    return (
        <>
            <div className="kyc-worldview">
                <div className="group">
                    <select value={origin?.id} onChange={handleOriginChange}>
                        <option value={-1}> Choose Origin </option>

                        {getOriginOptions().map((item, key) => {
                            return (<option key={key} value={item.id}> {item.name} </option>)
                        })}


                    </select>
                </div>
                <div className="visual-inner">
                    <Canvas form={form} origin={origin} origins={origins} country={country} countries={countries} />
                </div>
            </div>
        </>
    )
}