// Packages
import React, { useState, useEffect, Suspense } from 'react'
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import { useSelector } from "react-redux"

// redux 
// import { formState, selectedOrigin, selectedCountry, getOrigins, getCountries } from "redux/reducers/kyc/kycSlice";

// Local
import Controls from 'three/controls';
import Globe from 'three/globe';
import Box from 'three/box';

// Styles
import './canvas.scss';

export default function ThreeCanvas({ form, origin, origins, country, countries, ...props }) {

    // const form = useSelector(formState);
    // const origin = useSelector(selectedOrigin);
    // const origins = useSelector(getOrigins);
    // const country = useSelector(selectedCountry);
    // const countries = useSelector(getCountries);

    const cameraDefault = new THREE.Vector3(0, 0, 170);
    const [cameraPosition, setCameraPosition] = useState(cameraDefault);

    const target = new THREE.Vector3(0, 0, 0);

    const [rotate, setRotate] = useState(false);

    useEffect(() => {
        updateArc();
    }, [origin, country])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if( form?.submitting ) {
            if( !rotate) {
                setRotate(true);
            }
        }
    }, [form]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if( rotate) {
            rotateToTarget();
        }
    },[rotate])  // eslint-disable-line react-hooks/exhaustive-deps

    function updateArc() {
        let originPos = new THREE.Vector3().copy(cameraDefault);
        if (origin?.globe) {
            const found = origins.find((item) => item.id === origin.id);
            originPos = new THREE.Vector3(found.globe.camera.x, found.globe.camera.y, found.globe.camera.z);
        }
       
        setCameraPosition(originPos);
    }
    
    function rotateToTarget() {
        let countryPos = new THREE.Vector3(0, 0, 0);
        if (country?.globe) {
            const found = countries.find((item) => item.id === country.id);
            countryPos = new THREE.Vector3(found.globe.camera.x, found.globe.camera.y, found.globe.camera.z);    
            
        }
        setCameraPosition(countryPos);
    }

    function onRotateFinish() {
        setRotate(false);
    }

    return (
        <div className="canvas">
            <Suspense fallback={null}>
                <Canvas gl={{ antialias: true }} pixelRatio={window.devicePixelRatio}>
                    {/* <Stats showPanel={0} className={statsClass} parent={'.globe'} {...props} /> */}
                    
                    {/* Lights */}
                    <ambientLight intensity={0.5} />
                    <pointLight position={[10, 10, 10]} />

                    {/* Camera controls (Orbit controls) */}
                    <Controls target={target} position={cameraPosition} onRotateFinish={onRotateFinish} /> 

                    {/* Objects */}
                    <Box position={[-1.2, 0, 0]} />
                    <Box position={[1.2, 0, 0]} />
                    <Globe origin={origin} country={country} countries={countries} />
                </Canvas>
            </Suspense>
        </div>
    )
}