// Packages
import { Link } from "react-router-dom";

// Local

// Helpers
import { useDetailed } from "helpers/useDetailed";

// Styles
import './home.scss';

export default function Home(props) {
    let detailed = useDetailed();

    return (
        <div className="route-home">
            <div className="page">
                <div className="page-content">

                    <div className="buttons">
                        <Link to="/kyc">
                            <div className="button">
                                KYC
                            </div>
                        </Link>
                        <Link to="/business">
                            <div className="button">
                                Business
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}