import React, { useState, useEffect } from 'react'
import ThreeGlobe from 'three-globe';
import { extend } from '@react-three/fiber';

// Data
import globeData from 'data/globedata.json';

extend({ ThreeGlobe });

function GlobeMesh({ origin, country, countries, ...props }) {

    const [globe, setGlobe] = useState(null);

    useEffect(() => {
        createGlobe();
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updateGlobe()
    }, [origin, country]) // eslint-disable-line react-hooks/exhaustive-deps

    function getCountryById(id) {
        if (countries && countries.length > 0) {
            for (let i = 0; i < countries.length; i++) {
                if (Number(id) === countries[i].id) {
                    return countries[i];
                }
            }
        }
        return null;
    }

    function createGlobe() {
        if (!origin && !country) {
            console.log('origin', origin);
            console.log('country', country);
            return false;
        }

        const originCountry = getCountryById(origin.id);
        const selectedCountry = getCountryById(country.id);

        let polyData = [];
        if (globeData.features) {
            for (let i = 0; i < globeData.features.length; i++) {
                if (globeData.features[i].properties.ISO_A2 === originCountry?.code || globeData.features[i].properties.ISO_A2 === selectedCountry?.code) {
                    polyData.push(globeData.features[i]);
                }
            }
        }


        let gData = [];
        if (countries && countries.length > 0) {
            for (let i = 0; i < countries.length; i++) {
                if (countries[i].id === originCountry?.id || countries[i].id === selectedCountry?.id) {
                    let country = {
                        lat: countries[i]?.globe?.label?.y,
                        lng: countries[i]?.globe?.label?.x,
                        size: 2,
                        color: 'red',
                        label: countries[i].name
                    }
                    gData.push(country);
                }
            }
        }


        const arcsData = [];
        if (originCountry && selectedCountry) {
            let data = {
                startLat: originCountry.globe.label.y,
                startLng: originCountry.globe.label.x,
                endLat: selectedCountry.globe.label.y,
                endLng: selectedCountry.globe.label.x,
                color: 'yellow'
            }

            arcsData.push(data);
        }

        let globeMesh = new ThreeGlobe({
            animateIn: false,
        })
            // Texture
            .globeImageUrl('./earth-blue-marble.jpeg')

            // Polygon data
            .polygonsData(polyData)
            .polygonCapColor(() => 'rgba(17, 80, 152,0.50)')
            .polygonSideColor(() => 'rgba(100,0,0,0)')
            .polygonStrokeColor(() => '#0D1428')

            // Labels
            .labelsData(gData)
            .labelText(d => d.label)
            .labelAltitude(0.02)
            .labelSize('size')
            .labelDotRadius(d => d.size / 5)
            .labelColor('color')

        setGlobe(globeMesh);
    }

    function updateGlobe() {
        if (globe) {
            const originCountry = getCountryById(origin.id);
            const selectedCountry = getCountryById(country.id);

            let polyData = [];
            if (globeData.features) {
                for (let i = 0; i < globeData.features.length; i++) {
                    if (globeData.features[i].properties.ISO_A2 === originCountry?.code || globeData.features[i].properties.ISO_A2 === selectedCountry?.code) {
                        polyData.push(globeData.features[i]);
                    }
                }
            }

            let gData = [];
            if (countries && countries.length > 0) {
                for (let i = 0; i < countries.length; i++) {
                    if (countries[i].id === originCountry?.id || countries[i].id === selectedCountry?.id) {
                        let country = {
                            lat: countries[i]?.globe?.label?.y,
                            lng: countries[i]?.globe?.label?.x,
                            size: 2,
                            color: 'red',
                            label: countries[i].name
                        }
                        gData.push(country);
                    }
                }
            }

            const arcsData = [];
            if (originCountry && selectedCountry) {
                let data = {
                    startLat: originCountry.globe.label.y,
                    startLng: originCountry.globe.label.x,
                    endLat: selectedCountry.globe.label.y,
                    endLng: selectedCountry.globe.label.x,
                    color: 'yellow'
                }

                arcsData.push(data);
            }

            let globeMesh = globe;
            globeMesh
                // polygon
                .polygonsData(polyData)
                .polygonCapColor(() => 'rgba(17, 80, 152,0.50)')
                .polygonSideColor(() => 'rgba(100,0,0,0)')
                .polygonStrokeColor(() => '#0D1428')

                // arcs
                .arcsData(arcsData)
                .arcColor('color')
                .arcDashLength(1)
                .arcDashGap(4)
                .arcDashInitialGap(() => Math.random() * 5)
                .arcDashAnimateTime(1000)

                //labels
                .labelsData(gData)
                .labelText(d => d.label)
                .labelAltitude(0.02)
                .labelSize('size')
                .labelDotRadius(d => d.size / 5)
                .labelColor('color');
        }
    }

    return (
        <>
            {globe ?
                <primitive object={globe} dispose={null} />
                : <> </>
            }
        </>
    )
}

export default React.memo(GlobeMesh);