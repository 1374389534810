// Packages
import React, { useEffect, useState, useContext } from 'react';

// Local 
import { fakeAuthProvider } from './fakeauth';

let AuthContext = React.createContext(null);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ ...props }) {
    let lsKey = 'gdc.user';
    let [user, setUser] = useState(null);

    useEffect(() => {
        const lsUser = getLSUser();
        if (lsUser) {
            setUser(lsUser);
        }
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLSUser(user);
    }, [user])  // eslint-disable-line react-hooks/exhaustive-deps

    function getLSUser() {
        let data = localStorage.getItem(lsKey);
        if (data) {
            return JSON.parse(data);
        }
        return false;
    }

    function setLSUser(lsUser) {
        localStorage.setItem(lsKey, JSON.stringify(lsUser));
    }

    let signin = (newUser, success, error) => {
        return fakeAuthProvider.signin(newUser, (res) => {
            if (res) {
                setUser(newUser);
                success();
            }
            else {
                error();
            }
        });
    };

    let signout = (callback) => {
        return fakeAuthProvider.signout(() => {
            setUser(null);
            callback();
        });
    };

    let value = { user, signin, signout };

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}
