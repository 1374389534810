// Local
import KYCForm from 'components/kyc-form';
import KYCWorldview from 'components/kyc-worldview';
import KYCWaterfall from 'components/kyc-waterfall';
import KYCResult from 'components/kyc-result';

// Helpers
import { useDetailed } from "helpers/useDetailed";

// Data
import exampleJson from 'data/example.json';

// Styles
import './kyc.scss';

export default function KYC(props) {
    let detailed = useDetailed();

    return (
        <div className="route-kyc">
            <div className="page">
                <div className="page-title">
                    <h3> KYC </h3>
                </div>

                <div className="page-content">
                    <div className="content">
                        <KYCForm />
                    </div>
                    <div className="content content-worldview">
                        <KYCWorldview />
                    </div>
                    <div className="content">
                        <KYCWaterfall />
                    </div>
                    <div className="content content-result">
                        <KYCResult data={exampleJson} />
                    </div>
                    {detailed.state ?
                        <div className="content content-json">
                            <div className="group">
                                <h3> Full json example: </h3>
                                <textarea className="text-json" defaultValue={JSON.stringify(exampleJson, null, 2)} />
                            </div>
                        </div>
                        : <> </>
                    }
                </div>
            </div>
        </div>
    )
}