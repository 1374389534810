import { createSlice } from '@reduxjs/toolkit';

import defaultData from 'data/kyc.json';

const initialState = {
    form: {
        submitting: false,
        submitted: false,
        result: 'none',
        results: [],
    },
    selected: {
        origin: { id: -1, name: '' },
        tenant: { id: -1, name: '' },
        country: { id: -1, name: '' },
        source: { id: -1, name: '' },
        person: { id: -1, name: '' },
    },
    options: {
        waterfall: [],
    },
    default: {
        origins: defaultData?.countries,
        tenants: defaultData?.tenants,
        countries: defaultData?.countries,
        sources: defaultData?.sources,
        people: defaultData?.people,
    }
};

export const kycSlice = createSlice({
    name: 'kyc',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // form states, submitting, submitted, finsihed 
        setResult: (state, action) => {
            const { result, results } = { ...action.payload };

            state.form.result = result;
            state.form.results = results;
        },
        setResults: (state, action) => {
            state.form.results = action.payload;
        },
        setSubmitting: (state, action) => {
            state.form.submitting = action.payload;
            state.form.submitted = false;
        },
        setSubmitted: (state, action) => {
            state.form.submitting = false;
            state.form.submitted = action.payload;
        },
        setOrigin: (state, action) => {
            state.selected.origin = action.payload;
        },
        setTenant: (state, action) => {
            state.selected.tenant = action.payload;
            state.form.submitting = false;
            state.form.submitted = false;
        },
        setCountry: (state, action) => {
            state.selected.country = action.payload;
            state.form.submitting = false;
            state.form.submitted = false;
        },
        setSource: (state, action) => {
            state.selected.source = action.payload;
            state.form.submitting = false;
            state.form.submitted = false;
        },
        setPerson: (state, action) => {
            state.selected.person = action.payload;
            state.form.submitting = false;
            state.form.submitted = false;
        },

        calculateResult: (state, action) => {
            let opts = [];
            let result = 'none';
            let results = [];

            if (state?.selected?.tenant?.name !== '1+1' && state?.selected?.tenant?.name !== '2+2') {
                return state;
            }

            const { people, sources } = { ...action.payload };
            const person = state?.selected?.person;
            const tenant = state?.selected?.tenant;

            function getPersonById(id) {
                for (let i = 0; i < people.length; i++) {
                    if (Number(id) === people[i].id) {
                        return people[i];
                    }
                }
                return null;
            }
            function getSourceById(id) {
                if (sources && sources.length > 0) {
                    for (let i = 0; i < sources.length; i++) {
                        if (Number(id) === sources[i].id) {
                            return sources[i];
                        }
                    }
                }
                return null;
            }

            for (let p = 0; p < people.length; p++) {
                if (person.id === people[p].id) {
                    const personDetails = getPersonById(person.id);

                    if (people[p].sources) {
                        for (let s = 0; s < people[p].sources.length; s++) {
                            let src = { ...getSourceById(people[p].sources[s]) }

                            let res = 'none';
                            let matchCount = 0;
                            for (const match in src.matches) {
                                if (src.matches.hasOwnProperty(match)) {
                                    if (src.matches[match] && personDetails[match]) {
                                        matchCount += 1;
                                    }
                                }
                            }
                            if (matchCount >= 4) {
                                res = 'full';
                                result = 'full';
                            }
                            else if (matchCount >= 2) {
                                res = 'partial';
                                if (result !== 'full') {
                                    result = 'partial';
                                }
                            }

                            src.match = res;
                            if (src) {
                                opts.push(src);
                            }
                        }
                    }
                }
            }

            // Capture result, set skipped results
            let matchFound = false;
            let secondMatchFound = false;

            for (let i = 0; i < opts.length; i++) {
                if (tenant.type === '1+1' && matchFound) {
                    opts[i].match = 'skip';
                }
                else if (tenant.name === '2+2' && matchFound && secondMatchFound) {
                    opts[i].match = 'skip';
                }

                if (result === 'partial') {
                    if (tenant.type === '1+1' && !matchFound && opts[i].match === 'partial') {
                        result = 'partial';
                        if (results.length < 1) {
                            results.push(opts[i]);
                        }
                    }
                    else if (tenant.type === '2+2' && !secondMatchFound && opts[i].match === 'partial') {
                        result = 'partial';
                        if (results.length < 2) {
                            results.push(opts[i]);
                        }
                    }
                }

                if (result === 'full') {
                    if (tenant.type === '1+1' && opts[i].match === 'full') {
                        matchFound = true;
                        result = 'full'
                        results = [];
                        results.push(opts[i]);
                    }
                    else if (tenant.type === '2+2' && opts[i].match === 'full') {
                        if (!matchFound) {
                            matchFound = true;
                            results = [];
                            results.push(opts[i]);
                        }
                        else {
                            if (!secondMatchFound) {
                                secondMatchFound = true;
                                result = 'full';
                                results.push(opts[i]);
                            }
                        }
                    }
                }
            }

            state.options.waterfall = opts;

            state.form.result = result;
            state.form.results = results;
        }
    }
});

export const { setResult, setSubmitting, setSubmitted, setOrigin, setTenant, setCountry, setSource, setPerson, calculateResult } = kycSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// Form 
export const formState = (state) => state?.kyc?.form;

// Selected
export const selectedState = (state) => state?.kyc?.selected;
export const selectedValues = (state) => state.kyc?.selected;
export const selectedOrigin = (state) => state.kyc?.selected?.origin;
export const selectedTenant = (state) => state.kyc?.selected?.tenant;
export const selectedCountry = (state) => state.kyc?.selected?.country;
export const selectedSource = (state) => state.kyc?.selected?.source;
export const selectedPerson = (state) => state.kyc?.selected?.person;

// Options 
export const getWaterfallOptions = (state) => state.kyc?.options.waterfall;

// Defaults
export const getOrigins = (state) => state.kyc?.default?.origins;
export const getTenants = (state) => state.kyc?.default?.tenants;
export const getCountries = (state) => state.kyc?.default?.countries;
export const getSources = (state) => state.kyc?.default?.sources;
export const getPeople = (state) => state.kyc?.default?.people;
export const getBusinesses = (state) => state.kyc?.default?.businesses;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

export default kycSlice.reducer;
