// Packages
import { Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router';

// Local
import PrivateRoute from "components/private-route/private-route";
import { AuthProvider } from "components/auth/auth";

// Routes
import Home from 'routes/home';
import KYC from 'routes/kyc';
import BV from 'routes/bv';

import Config from 'routes/config';
import Login from 'routes/login';
import Logout from 'routes/logout';

export default function Router() {
    return (
        <>
            <AuthProvider>
                <Routes>
                    <Route index path="/" element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    } />
                     <Route index path="/kyc" element={
                        <PrivateRoute>
                            <KYC />
                        </PrivateRoute>
                    } />
                    <Route index path="/business" element={
                        <PrivateRoute>
                            <BV />
                        </PrivateRoute>
                    } />

                    <Route index path="/config" element={
                        <PrivateRoute>
                            <Config />
                        </PrivateRoute>
                    } />

                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </AuthProvider>
        </>
    )
}