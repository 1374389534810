import { createSlice } from '@reduxjs/toolkit';

import defaultData from 'data/bv.json';


const initialState = {
    form: {
        submitting: false,
        submitted: false,
        result: 'none',
        results: [],
    },
    selected: {
        origin: { id: -1, name: '' },
        tenant: { id: -1, name: '' },
        country: { id: -1, name: '' },
        source: { id: -1, name: '' },
        business: { id: -1, name: '' },
    },
    options: {
        waterfall: [],
    },
    default: {
        origins: defaultData?.countries,
        tenants: defaultData?.tenants,
        countries: defaultData?.countries,
        sources: defaultData?.sources,
        businesses: defaultData?.businesses,
    }
};

export const bvSlice = createSlice({
    name: 'bv',
    initialState,
    reducers: {
        setResult: (state, action) => {
            const { result, results } = { ...action.payload };
            state.form.result = result;
            state.form.results = results;
        },
        setResults: (state, action) => {
            state.form.results = action.payload;
        },
        setSubmitting: (state, action) => {
            state.form.submitting = action.payload;
            state.form.submitted = false;
        },
        setSubmitted: (state, action) => {
            state.form.submitting = false;
            state.form.submitted = action.payload;
        },

        setOrigin: (state, action) => {
            state.selected.origin = action.payload;
        },
        setTenant: (state, action) => {
            state.selected.tenant = action.payload;
            state.form.submitting = false;
            state.form.submitted = false;
        },
        setCountry: (state, action) => {
            state.selected.country = action.payload;
            state.form.submitting = false;
            state.form.submitted = false;
        },
        setSource: (state, action) => {
            state.selected.source = action.payload;
            state.form.submitting = false;
            state.form.submitted = false;
        },
        setBusiness: (state, action) => {
            state.selected.business = action.payload;
            state.form.submitting = false;
            state.form.submitted = false;
        },

        calculateResult: (state, action) => {
            console.log('calculate result', 'start');

            let opts = [];
            let result = 'none';
            let results = [];

            if (state?.selected?.tenant?.type !== 'bv' && state?.selected?.tenant?.type !== 'bv+') {
                return state;
            }

            const { businesses, sources } = { ...action.payload };
            const business = state?.selected?.business;
            const tenant = state?.selected?.tenant;

            console.log('calculate result', 'businesses', businesses);
            console.log('calculate result', 'business', business);


            function getBusinessById(id) {
                for (let i = 0; i < businesses.length; i++) {
                    if (Number(id) === businesses[i].id) {
                        return businesses[i];
                    }
                }
                return null;
            }
            function getSourceById(id) {
                if (sources && sources.length > 0) {
                    for (let i = 0; i < sources.length; i++) {
                        if (Number(id) === sources[i].id) {
                            return sources[i];
                        }
                    }
                }
                return null;
            }

            for (let p = 0; p < businesses.length; p++) {
                if (business.id === businesses[p].id) {
                    const businessDetails = { ...getBusinessById(business.id) };
                    businessDetails.name = businessDetails.organizationame;


                    if (businesses[p].sources) {
                        for (let s = 0; s < businesses[p].sources?.[tenant.type].length; s++) {
                            let src = { ...getSourceById(businesses[p].sources?.[tenant.type][s]) }
                            console.log('calculate result', 'src', src);


                            let res = 'none';
                            let matchCount = 0;
                            for (const match in src.matches) {
                                if (src.matches.hasOwnProperty(match)) {
                                    if (src.matches[match] && businessDetails[match]) {
                                        matchCount += 1;
                                    }
                                }
                            }
                            if (matchCount >= 5) {
                                res = 'full';
                                result = 'full';
                            }
                            else if (matchCount >= 2) {
                                res = 'partial';
                                if (result !== 'full') {
                                    result = 'partial';
                                }
                            }

                            src.match = res;
                            if (src) {
                                opts.push(src);
                            }
                        }
                    }
                }
            }

            // Capture result, set skipped results
            let matchFound = false;
            // let secondMatchFound = false;

            for (let i = 0; i < opts.length; i++) {
                console.log('opts', opts[i]);
                if ( matchFound) {
                    opts[i].match = 'skip';
                }
                // else if (tenant.name === 'bv+' && matchFound && secondMatchFound) {
                //     opts[i].match = 'skip';
                // }

                if (result === 'partial') {
                    if (!matchFound && opts[i].match === 'partial') {
                        result = 'partial';
                        if (results.length < 1) {
                            results.push(opts[i]);
                        }
                    }
                    // else if (tenant.type === 'bv+' && !secondMatchFound && opts[i].match === 'partial') {
                    //     result = 'partial';
                    //     if (results.length < 2) {
                    //         results.push(opts[i]);
                    //     }
                    // }
                }

                if (result === 'full') {
                    if (opts[i].match === 'full') {
                        matchFound = true;
                        result = 'full'
                        results = [];
                        results.push(opts[i]);
                    }
                    // else if (tenant.type === 'bv+' && opts[i].match === 'full') {
                    //     if (!matchFound) {
                    //         matchFound = true;
                    //         results = [];
                    //         results.push(opts[i]);
                    //     }
                    //     else {
                    //         if (!secondMatchFound) {
                    //             secondMatchFound = true;
                    //             result = 'full';
                    //             results.push(opts[i]);
                    //         }
                    //     }
                    // }
                }
            }

            state.options.waterfall = opts;

            state.form.result = result;
            state.form.results = results;
        }
    }
});

export const { setResult, setSubmitting, setSubmitted, setOrigin, setTenant, setCountry, setSource, setBusiness, calculateResult } = bvSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// Form state
export const formState = (state) => state?.bv?.form;

// Selected State
export const selectedState = (state) => state?.bv?.selected;
export const selectedValues = (state) => state.bv?.selected;
export const selectedOrigin = (state) => state.bv?.selected?.origin;
export const selectedTenant = (state) => state.bv?.selected?.tenant;
export const selectedCountry = (state) => state.bv?.selected?.country;
export const selectedSource = (state) => state.bv?.selected?.source;
export const selectedBusiness = (state) => state.bv?.selected?.business;

// Waterfall
export const getWaterfallOptions = (state) => state.bv?.options.waterfall;

// Defaults
export const getOrigins = (state) => state.bv?.default?.origins;
export const getTenants = (state) => state.bv?.default?.tenants;
export const getCountries = (state) => state.bv?.default?.countries;
export const getSources = (state) => state.bv?.default?.sources;
export const getPeople = (state) => state.bv?.default?.people;
export const getBusinesses = (state) => state.bv?.default?.businesses;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

export default bvSlice.reducer;
