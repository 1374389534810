// Local
import BVForm from 'components/bv-form';
import BVWorldview from 'components/bv-worldview';
import BVWaterfall from 'components/bv-waterfall';
import BVResult from 'components/bv-result';

// Helpers
import { useDetailed } from "helpers/useDetailed";

// Data
import exampleJson from 'data/example.json';

// Styles
import './bv.scss';

export default function BV(props) {
    let detailed = useDetailed();

    return (
        <div className="route-bv">
            <div className="page">

                <div className="page-title"> 
                    <h3> Business </h3>
                </div>

                <div className="page-content">
                    <div className="content">
                        <BVForm />
                    </div>
                    <div className="content content-worldview">
                        <BVWorldview />
                    </div>
                    <div className="content">
                        <BVWaterfall />
                    </div>
                    <div className="content content-result">
                        <BVResult data={exampleJson} />
                    </div>
                    {detailed.state ?
                        <div className="content content-json">
                            <div className="group">
                                <h3> Full json example: </h3>
                                <textarea className="text-json" defaultValue={JSON.stringify(exampleJson, null, 2)} />
                            </div>
                        </div>
                        : <> </>
                    }
                </div>
            </div>
        </div>
    )
}