// Packages
import React from 'react';
import { Navigate, useLocation } from 'react-router';

import { useAuth } from 'components/auth/auth';

export default function PrivateRoute({ path, ...props }) {
    let auth = useAuth();
    let location = useLocation();

    if ( !auth.user) {
        // console.log('redirect');
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        // navigate("/login", { replace: true });
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return (
        <>
            {props.children}
        </>
    )
}