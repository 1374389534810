const fakeAuthProvider = {
    isAuthenticated: false,
    signin(user, callback) {
        if (user.username === 'Admin' && user.password === 'Password') {
            fakeAuthProvider.isAuthenticated = true;
            callback(true);
        }
        else {
            callback(false);
        }
    },
    signout(callback) {
        fakeAuthProvider.isAuthenticated = false;
        setTimeout(callback, 100);
    }
};

export { fakeAuthProvider };