// Packages
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux"

// Local
import ResultIcon from 'components/result-icon';
import MatchRule from 'components/match-rule';

// Redux
import { formState } from "redux/reducers/kyc/kycSlice";

// Helpers
import { useDetailed } from "helpers/useDetailed";

export default function KYCResult({ data, ...props }) {
    let detailed = useDetailed();
    const form = useSelector(formState);

    const [indent] = useState(2);
    const [passScore, setPassScore] = useState();

    useEffect(() => {
        if (data?.identity?.codes) {
            let codes = data.identity.codes;

            if (form?.result) {
                if (form.result === 'full') {
                    codes.reliability = 10;
                }
                else if (form.result === 'partial') {
                    codes.reliability = 20;
                }
                else {
                    codes.reliability = 30;
                }
            }

            codes.messages = [];
            setPassScore(codes);
        }
    }, [data, form]);

    return (
        <>
            <div className="group">
                <h3> Reliability score </h3>
                <div className="flex flex-parent">
                    <div className="flex column">

                        <div className="flex row start">


                            {form?.submitted && form?.result === 'full' ?
                                <ResultIcon score={10} />
                                : <> </>}

                            {form?.submitted && form?.result === 'partial' ?
                                <ResultIcon score={20} />
                                : <> </>}

                            {form?.submitted && form?.result === 'none' ?
                                <ResultIcon score={30} />
                                : <> </>}


                            {form?.submitted && form?.results && form?.results.length > 0 ?
                                form?.results.map((item, key) => {
                                    return (<MatchRule key={key} item={item} />)
                                })
                                : <> </>
                            }

                        </div>
                        <div className="description">
                            Data sources are accessed for verification in a waterfall to ensure high quality matches and pass rates.
                            A Reliability Score, is provided for every search.
                            <br />
                            Based on the rules we have configured to your needs, it confirms whether verification is a Pass, a Partial Match, or a No Match.
                            Once a Pass has been confirmed, the waterfall is stopped and result is returned via the API.
                        </div>
                    </div>
                    <div className="flex score-json" >
                        {detailed.state ?
                            <>
                                <textarea className='score-json' value={JSON.stringify(passScore, null, indent)} />
                            </>
                            : <> </>}
                    </div>
                </div>
            </div>
        </>
    )
}