// Packages
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import Switch from "react-switch";
import cx from 'classnames';

// Data
import defaultData from 'data/kyc.json';

// Styles
import './config.scss';

export default function Config(props) {
    const lsKey = "gdc.demo";
    const [loaded, setLoaded] = useState(false);

    const [selected, setSelected] = useState({
        tenant: { id: null, name: '' },
        country: { id: null, name: '' },
        person: { id: null, name: '' },
        source: { id: null, name: '' },
    });

    const [tenants, setTenants] = useState([]);
    const [countries, setCountries] = useState([]);
    const [people, setPeople] = useState([]);
    const [sources, setSources] = useState([]);

    useEffect(() => {
        getData();
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (loaded) {
            saveData();
        }
    }, [tenants])  // eslint-disable-line react-hooks/exhaustive-deps

    function getData() {
        let data = localStorage.getItem(lsKey);
        console.log('ls data', data);

        data = JSON.parse(data);


        if (!data || !data.tenants) {
            console.log('setting default data');
            data = getDefaultData();
        }

        if (data.tenants) {
            setTenants(data.tenants);
        }
        if (data.countries) {
            setCountries(data.countries);
        }
        if (data.people) {
            setPeople(data.people);
        }
        if (data.sources) {
            setSources(data.sources);
        }
        setLoaded(true);
    }

    function saveData() {
        let data = {
            tenants,
            countries,
            people,
            sources,
        }
        // console.log('save data', data);
        localStorage.setItem(lsKey, JSON.stringify(data));
    }

    function clearData() {
        localStorage.removeItem(lsKey);
        window.location.reload(false);
    }

    function getDefaultData() {
        // console.log('default', defaultData);
        const data = defaultData;
        return data;
    }

    function onTenantSelect(id) {
        let tenant = getTenant(id);
        setSelected({ tenant });
    }
    function onCountrySelect(id) {
        let country = getCountry(id);
        setSelected({ tenant: selected.tenant, country });
    }
    function onPersonSelect(id) {
        let person = getPerson(id);
        setSelected({ tenant: selected.tenant, country: selected.country, person });
    }
    function onSourceSelect(id) {
        let source = getSource(id);
        setSelected({ tenant: selected.tenant, country: selected.country, person: selected.person, source });
    }

    function onTenantToggle(id) {
        console.log('onTenantToggle', id);

        let _tenants = [...tenants];
        for (let t = 0; t < _tenants.length; t++) {
            if (_tenants[t].id === id) {
                _tenants[t].active = !_tenants[t].active;
            }
        }
        setTenants(_tenants);
    }


    function onCountryToggle(id) {
        console.log('onCountryToggle', id);

        let _tenants = [...tenants];
        for (let t = 0; t < _tenants.length; t++) {
            if (selected.tenant.id === _tenants[t].id) {
                let match = false;
                for (let c = 0; c < _tenants[t].countries.length; c++) {
                    if (!match && _tenants[t].countries[c].id === id) {
                        match = true;
                        _tenants[t].countries.splice(c, 1);

                    }
                }
                if (!match) {
                    _tenants[t].countries.push({
                        id: id,
                        sources: [],
                    })
                }
            }
        }
        setTenants(_tenants);
    }

    function onPersonToggle(id) {
        console.log('onSourceToggle', id);

        let _tenants = [...tenants];
        for (let t = 0; t < _tenants.length; t++) {
            if (selected.tenant.id === _tenants[t].id) {
                let match = false;
                for (let c = 0; c < _tenants[t].countries.length; c++) {
                    if (selected.country.id === _tenants[t].countries[c].id) {
                        if( tenants[t].countries[c].people ) {
                            for (let p = 0; p < tenants[t].countries[c].people.length; p++) {
                                if (!match && _tenants[t].countries[c].people[p].id === id) {
                                    match = true;
                                    _tenants[t].countries[c].people.splice(p, 1);
                                }
                            }
                        }
                    }
                    if (!match) {
                        if( !_tenants[t].countries[c].people ){
                            _tenants[t].countries[c].people = [];
                        }
                        _tenants[t].countries[c].people.push({
                            id: id,
                            sources: [],
                        })
                    }
                }

            }
        }
        setTenants(_tenants);
    }

    function onSourceToggle(id) {
        console.log('onPersonToggle', id);

        let _tenants = [...tenants];
        for (let t = 0; t < _tenants.length; t++) {
            if (selected.tenant.id === _tenants[t].id) {
                let match = false;
                for (let c = 0; c < _tenants[t].countries.length; c++) {
                    if (selected.country.id === _tenants[t].countries[c].id) {

                        for (let p = 0; p < tenants[t].countries[c].people.length; p++) {
                            if (selected.person.id === _tenants[t].countries[c].people[p].id) {

                                for (let s = 0; s < tenants[t].countries[c].people[p].sources.length; s++) {
                                    if (!match && tenants[t].countries[c].people[p].sources[s].id === id) {
                                        match = true;
                                        _tenants[t].countries[c].people[p].sources.splice(s, 1);
                                    }
                                }
                            }
                            if (!match) {
                                _tenants[t].countries[c].people[p].sources.push({
                                    id: id,
                                    json: 'test'
                                    // people: [],
                                })
                            }
                        }
                    }
                }
            }
        }
        setTenants(_tenants);
    }

    function onMatchChange(match) {
        console.log('onMatchChange', match);

        let _tenants = [...tenants];
        for (let t = 0; t < _tenants.length; t++) {
            if (selected.tenant.id === _tenants[t].id) {
                for (let c = 0; c < _tenants[t].countries.length; c++) {
                    if (selected.country.id === _tenants[t].countries[c].id) {

                        for (let p = 0; p < tenants[t].countries[c].people.length; p++) {
                            if (selected.person.id === _tenants[t].countries[c].people[p].id) {

                                for (let s = 0; s < tenants[t].countries[c].people[p].sources.length; s++) {
                                    if ( selected.source.id === tenants[t].countries[c].people[p].sources[s].id ) {
                                        tenants[t].countries[c].people[p].sources[s].match = match;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        setTenants(_tenants);
    } 

    function onJSONChange(json) {
        console.log('onJSONChange', json);


        let _tenants = [...tenants];
        for (let t = 0; t < _tenants.length; t++) {
            if (selected.tenant.id === _tenants[t].id) {

                for (let c = 0; c < _tenants[t].countries.length; c++) {
                    if (selected.country.id === _tenants[t].countries[c].id) {

                        for (let p = 0; p < tenants[t].countries[c].people.length; p++) {
                            if (selected.person.id === _tenants[t].countries[c].people[p].id) {

                                for (let s = 0; s < tenants[t].countries[c].people[p].sources.length; s++) {
                                    if ( selected.source.id === tenants[t].countries[c].people[p].sources[s].id ) {
                                        tenants[t].countries[c].people[p].sources[s].json = json;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        setTenants(_tenants);
    }



    function getTenant(id) {
        for (let i in tenants) {
            if (tenants[i].id === id) {
                return tenants[i];
            }
        }
        return null;
    }

    function getCountry(id) {
        for (let i in countries) {
            if (countries[i].id === id) {
                return countries[i];
            }
        }
        return null;
    }
    function getSource(id) {
        for (let i in sources) {
            if (sources[i].id === id) {
                return sources[i];
            }
        }
        return null;
    }
    function getPerson(id) {
        for (let i in people) {
            if (people[i].id === id) {
                return people[i];
            }
        }
        return null;
    }



    return (
        <div className="cmp-config">
            {/* <h3> Config </h3> */}

            {/* <span className="pad"> Tenant: {selected ? JSON.stringify(selected) : 'none'} </span> */}

            <div className="flex right padding">



                <div className="flex-grow"> </div>

                <button onClick={clearData}> Clear Data </button>

                <button> Export config </button>
                <button> Import config </button>
            </div>

            <div className="grid">
                <Tenants data={tenants} selected={selected} onSelect={onTenantSelect} onToggle={onTenantToggle} />
                <Countries data={countries} selected={selected} onSelect={onCountrySelect} onToggle={onCountryToggle} />
                <People data={people} selected={selected} onSelect={onPersonSelect} onToggle={onPersonToggle} />
                <Sources data={sources} selected={selected} onSelect={onSourceSelect} onToggle={onSourceToggle} />

                <Json data={getSource(selected.source && selected.source.id)} selected={selected} onMatchChange={onMatchChange} onJSONChange={onJSONChange} />
            </div>
        </div>
    )
}

function Tenants({ data, selected, onSelect, onToggle, ...props }) {

    function handleClick(key, item) {
        if (onSelect) {
            onSelect(item.id)
        }
    }

    function handleToggle(key, item) {
        if (onToggle) {
            onToggle(item.id);
        }
    }

    return (
        <div className="col">
            <div className="flex">
                <div className="title"> Tenants </div>
                <div className="add"> <FaPlus /> </div>
            </div>

            {data && data.map((item, key) => {
                const className = cx(
                    'grid-item-inner',
                    { 'selected': selected.tenant.id === item.id }
                )
                return (
                    <div className="grid-item" key={key} onClick={() => { handleClick(key, item) }}>
                        <div className={className}>
                            <div className="grid-item-text"> {item.name} </div>
                            <Switch checked={item.active} onChange={(checked) => { handleToggle(key, item) }} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

function Countries({ data, selected, onSelect, onToggle, ...props }) {

    function handleClick(key, item) {
        if (!isDisabled()) {
            if (onSelect) {
                onSelect(item.id)
            }
        }
    }

    function handleToggle(key, item) {
        if (!isDisabled()) {
            if (onToggle) {
                onToggle(item.id);
            }
        }
    }

    function isToggled(id) {
        if (selected && selected.tenant && selected.tenant.countries) {
            let countries = selected.tenant.countries;
            for (let c = 0; c < countries.length; c++) {
                if (countries[c].id === id) {
                    return true;
                }
            }
        }
        return false;
    }

    function isDisabled(id) {
        if (selected && selected.tenant && selected.tenant.id !== null) {
            if (selected.tenant.active) {
                return false;
            }
        }
        return true;
    }

    return (
        <div className="col">
            <div className="flex">
                <div className="title"> Countries </div>
                <div className="add"> <FaPlus /> </div>
            </div>


            {data && data.map((item, key) => {
                const classGridItem = cx(
                    'grid-item',
                    { 'disabled': isDisabled(item.id) }
                )

                const className = cx(
                    'grid-item-inner',
                    { 'selected': selected.country && selected.country.id === item.id }
                )
                return (
                    <div className={classGridItem} key={key} onClick={() => { handleClick(key, item) }}>
                        <div className={className}>
                            <div className="grid-item-text"> {item.name} </div>
                            <Switch checked={isToggled(item.id)} onChange={(checked) => { handleToggle(key, item) }} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

function People({ data, selected, onSelect, onToggle, ...props }) {

    function handleClick(key, item) {
        if (!isDisabled()) {
            if (onSelect) {
                onSelect(item.id)
            }
        }
    }

    function handleToggle(key, item) {
        if (!isDisabled()) {
            if (onToggle) {
                onToggle(item.id);
            }
        }
    }

    function isToggled(id) {
        if (selected && selected.tenant && selected.country) {
            if (selected.tenant.countries) {

                for (let c = 0; c < selected.tenant.countries.length; c++) {
                    if (selected.tenant.countries[c].id === selected.country.id) {
                        if (selected.tenant.countries[c].people) {

                            for (let p = 0; p < selected.tenant.countries[c].people.length; p++) {
                                if (selected.tenant.countries[c].people[p].id === id) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    function isDisabled() {
        if (selected && selected.tenant && selected.tenant.id !== null && selected.tenant.active) {
            if (selected.country && selected.tenant.countries) {
                let countries = selected.tenant.countries;
                for (let c = 0; c < countries.length; c++) {
                    if (selected.country.id === countries[c].id) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    return (
        <div className="col">
            <div className="flex">
                <div className="title"> People </div>
                <div className="add"> <FaPlus /> </div>
            </div>
            {data && data.map((item, key) => {
                const classGridItem = cx(
                    'grid-item',
                    { 'disabled': isDisabled() }
                )

                const className = cx(
                    'grid-item-inner',
                    { 'selected': selected.person && selected.person.id === item.id }
                )
                return (
                    <div className={classGridItem} key={key} onClick={() => { handleClick(key, item) }}>
                        <div className={className}>
                            <div className="grid-item-text"> {item.name} </div>
                            <Switch checked={isToggled(item.id)} onChange={(checked) => { handleToggle(key, item) }} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

function Sources({ data, selected, onSelect, onToggle, ...props }) {

    function handleClick(key, item) {
        if (!isDisabled()) {
            if (onSelect) {
                onSelect(item.id)
            }
        }
    }

    function handleToggle(key, item) {
        if (!isDisabled()) {
            if (onToggle) {
                onToggle(item.id);
            }
        }
    }

    function isToggled(id) {

        if (selected && selected.tenant && selected.country && selected.person) {
            if (selected.tenant.countries) {

                for (let c = 0; c < selected.tenant.countries.length; c++) {
                    if (selected.tenant.countries[c].id === selected.country.id) {
                        if (selected.tenant.countries[c].people) {

                            for (let p = 0; p < selected.tenant.countries[c].people.length; p++) {
                                if (selected.tenant.countries[c].people[p].id === selected.person.id) {
                                    if (selected.tenant.countries[c].people[p].sources) {

                                        for (let s = 0; s < selected.tenant.countries[c].people[p].sources.length; s++) {
                                            if (selected.tenant.countries[c].people[p].sources[s].id === id) {
                                                return true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    function isDisabled() {
        if (selected && selected.tenant && selected.tenant.id !== null && selected.tenant.active) {
            if (selected.country && selected.tenant.countries) {
                let countries = selected.tenant.countries;
                for (let c = 0; c < countries.length; c++) {
                    if (selected.country.id === countries[c].id) {

                        if (selected.person && countries[c].people) {
                            let people = countries[c].people;

                            for (let p = 0; p < people.length; p++) {
                                if (selected.person.id === people[p].id) {
                                    return false;
                                }
                            }
                        }

                    }
                }
            }
        }
        return true;
    }

    return (
        <div className="col">
            <div className="flex">
                <div className="title"> Sources </div>
                <div className="add"> <FaPlus /> </div>
            </div>

            {data && data.map((item, key) => {
                const classGridItem = cx(
                    'grid-item',
                    { 'disabled': isDisabled() }
                )

                const className = cx(
                    'grid-item-inner',
                    { 'selected': selected.source && selected.source.id === item.id }
                )
                return (
                    <div className={classGridItem} key={key} onClick={() => { handleClick(key, item) }}>
                        <div className={className}>
                            <div className="grid-item-text"> {item.name} </div>
                            <Switch checked={isToggled(item.id)} onChange={(checked) => { handleToggle(key, item) }} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

function Json({ data, selected, onJSONChange, onMatchChange, ...props }) {

    const [source, setSource] = useState({
        id: null,
        json: '',
        match: ''
    });


    useEffect(() => {
        if (selected && selected.tenant && selected.country && selected.person) {
            if (selected.tenant.countries) {

                for (let c = 0; c < selected.tenant.countries.length; c++) {
                    if (selected.tenant.countries[c].id === selected.country.id) {
                        if (selected.tenant.countries[c].people) {

                            for (let p = 0; p < selected.tenant.countries[c].people.length; p++) {
                                if (selected.tenant.countries[c].people[p].id === selected.person.id) {
                                    if (selected.tenant.countries[c].people[p].sources) {

                                        for (let s = 0; s < selected.tenant.countries[c].people[p].sources.length; s++) {
                                            if ( selected.tenant.countries[c].people[p].sources[s].id === selected.source.id) {
                                                console.log('setSource', selected.tenant.countries[c].people[p].sources[s]);
                                                setSource(selected.tenant.countries[c].people[p].sources[s]);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    },[data])  // eslint-disable-line react-hooks/exhaustive-deps   


    function handleJSONChange(event) {

        let value = event.target.value;
        if( onJSONChange) {
            onJSONChange(value);
        }
    }

    function handleSourceMatch(match) {
        if( onMatchChange) {
            onMatchChange(match);
        }
    }

    function isDisabled() {
        if (selected && selected.tenant && selected.tenant.id !== null && selected.tenant.active) {
            if (selected.country && selected.tenant.countries) {
                let countries = selected.tenant.countries;
                for (let c = 0; c < countries.length; c++) {
                    if (selected.country.id === countries[c].id) {

                        if (selected.person && countries[c].people) {
                            let people = countries[c].people;

                            for (let p = 0; p < people.length; p++) {
                                if (selected.person.id === people[p].id) {
                                    if (selected.source && people[p].sources) {
                                        let sources = people[p].sources;

                                        for (let s = 0; s < sources.length; s++) {
                                            if (selected.source.id === sources[s].id) {
                                                return false;
                                            }
                                        }
                                    }

                                }
                            }
                        }

                    }
                }
            }
        }
        return true;
    }

    const className = cx(
        '',
        { 'disabled': isDisabled() }
    )

    return (
        <div className="col json">
            <div className="flex">
                <div className="title"> {source.name} </div>

            </div>
            <div className="flex left btns-match">
                <button className={ source.match === 'full' ? 'btn selected' : 'btn' } onClick={() => handleSourceMatch('full')}> Full Match </button>
                <button className={ source.match === 'partial' ? 'btn selected' : 'btn' } onClick={() => handleSourceMatch('partial')}> Partial Match </button>
                <button className={ source.match === 'none' ? 'btn selected' : 'btn' } onClick={() => handleSourceMatch('none')}> No Match </button>
            </div>
            <div className={className}>
                <textarea value={source.json} disabled={isDisabled} onChange={handleJSONChange} />
            </div>
        </div>
    )
}