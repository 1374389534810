// Packages
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import cx from 'classnames';

// Helpers
import { useDetailed } from "helpers/useDetailed";

// Redux 
import {
    setSubmitting, setSubmitted, formState,
    setTenant, selectedTenant,
    setCountry, selectedCountry,
    setPerson, selectedPerson,
    calculateResult,
    selectedState,

    getTenants, getCountries, getPeople, getSources 
} from "redux/reducers/kyc/kycSlice";

// Styles
import './kyc-form.scss';

export default function Form({ ...props }) {
    let detailed = useDetailed();
    const dispatch = useDispatch();
    const form = useSelector(formState);
    const selected = useSelector(selectedState);

    const tenant = useSelector(selectedTenant);
    const tenants = useSelector(getTenants);

    const country = useSelector(selectedCountry);
    const countries = useSelector(getCountries);

    const person = useSelector(selectedPerson);
    const people = useSelector(getPeople);

    const sources = useSelector(getSources);

    useEffect(() => {
        dispatch(setSubmitting(false));
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(calculateResult({people, sources}));
    }, [selected]) // eslint-disable-line react-hooks/exhaustive-deps

    function handleTenantChange(event) {
        const value = Number(event?.target?.value);
        if (value !== -1) {
            const found = tenants.find((item) => item.id === value);
            dispatch(setTenant(found));
        }
    }

    function handleCountryChange(event) {
        const value = Number(event?.target?.value);
        if (value !== -1) {
            const found = countries.find((item) => item.id === value);
            dispatch(setCountry(found));
        }
    }

    function handlePersonChange(event) {
        const value = Number(event?.target?.value);
        if (value !== -1) {
            const found = people.find((item) => item.id === value);
            dispatch(setPerson(found));
        }
    }

    function getCountryById(id) {
        for (let i = 0; i < countries.length; i++) {
            if (Number(id) === countries[i].id) {
                return countries[i];
            }
        }
        return null;
    }

    function getPersonById(id) {
        for (let i = 0; i < people.length; i++) {
            if (Number(id) === people[i].id) {
                return people[i];
            }
        }
        return null;
    }

    function getCountryOptions() {
        let options = [];

        if (countries) {
            for (let c in countries) {
                let country = getCountryById(countries[c].id);
                options.push(country);
            }
            options.sort(function(a, b) {
                return a.name > b.name ? 1 : -1;
            });
        }
        return options;
    }

    function getPeopleOptions() {
        let options = [];

        if (people) {
            for (let p in people) {
                let person = getPersonById(people[p].id);
                if (person) {
                    options.push(person);
                }
            }
        }

        return options;
    }

    function handleSubmit() {
        if (!form?.submitting) {
            if (tenant?.id > -1 && country?.id > -1 && person?.id > -1) {
                dispatch(setSubmitting(true))
                setTimeout(() => {
                    dispatch(setSubmitted(true))
                }, 1000)
            }
        }
    }

    function getUserJson() {

        let test = { ...getPersonById(person.id)};
        if (test) {
            // set countryCode of selected country;
            test.countryCode = country?.code ? country.code : '';

            // remove custom fields 
            delete test.id;
            delete test.sources;

            return test;
        }
    }

    const groupUser = cx(
        'group',
        { 'disabled': form?.submitting }
    )

    const groupCountry = cx(
        'group',
        { 'disabled': person?.id === -1 || form?.submitting }
    )

    const groupTenant = cx(
        'group',
        { 'disabled': country?.id === -1 || form?.submitting }
    )

    const submitClasses = cx(
        'button',
        { 'disabled': person?.id === -1 || country?.id === -1 || tenant?.id === -1 || form?.submitting }
    )



    return (
        <div className="form flex column grow">
            <div className={groupUser}>
                <h3> User: </h3>
                <select value={person?.id} onChange={handlePersonChange}>
                    <option value={-1}> Choose person </option>
                    {getPeopleOptions().map((item, key) => {
                        return (<option key={key} value={item.id}> {item.name} </option>)
                    })}

                </select>
            </div>

            <div className={groupCountry}>
                <h3> Country: </h3>
                <select value={country?.id} onChange={handleCountryChange}>
                    <option value={-1}> Choose country </option>

                    {getCountryOptions().map((item, key) => {
                        return (<option key={key} value={item.id}> {item.name} </option>)
                    })}
                </select>
            </div>

            <div className={groupTenant}>
                <h3> Product Config: </h3>
                <select value={tenant?.id} onChange={handleTenantChange}>
                    <option value={-1}> Choose config </option>

                    {tenants?.map((item, key) => {
                        return (<option key={key} value={item.id}> {item.name} </option>)
                    })}
                </select>
            </div>


            <div className="flex grow">
                {person?.id > -1 && detailed.state === true ?
                    <>
                        <div className="group flex">
                            <textarea value={JSON.stringify(getUserJson(), null, 2)} onChange={() => { }} />
                        </div>
                    </>
                    :
                    <> </>
                }
            </div>

            <div className="group button">
                <button className={submitClasses} onClick={() => handleSubmit()}> {form?.submitting ? 'Submitting' : 'Submit'} </button>
            </div>
        </div>
    )
}