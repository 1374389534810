// Packages
import { Link } from "react-router-dom";
import { useLocation} from 'react-router';

// Helpers
import { useDetailed } from "helpers/useDetailed";

// Images
import logo from 'images/logo.svg';

// Styles
import './nav.scss';

export default function Nav(props) {
    let detailed = useDetailed();   
    let location = useLocation();

    return (
        <div className="cmp-nav">
            <div className="flex">
                <div className="logo">
                    <img src={logo} alt="GDC logo" />
                </div>

                {location.pathname !== '/login' ?
                    <>
                        <div className="nav-item">
                            <Link to="/"> Home  </Link>
                        </div>
                        <div className="nav-item grow"> </div>
                        <div className="nav-item">
                            <input id="detailed" type="checkbox" onChange={() => detailed.toggle()} />
                            <label htmlFor="detailed"> Detailed </label>
                        </div>
                        <div className="nav-item logout">
                            <Link to="/logout"> Log out </Link>
                        </div>
                    </>
                    :
                    <>
                        <div className="nav-item grow">
                        </div>
                        <div className="nav-item login">
                            <Link to="/login"> Log in </Link>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}