
import { combineReducers } from 'redux'

import kycSlice from 'redux/reducers/kyc/kycSlice';
import bvSlice from 'redux/reducers/bv/bvSlice';

const rootReducer = combineReducers({
    kyc: kycSlice,
    bv: bvSlice,
})

export default rootReducer;