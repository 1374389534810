

// Packages
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

// Local
import { useAuth } from 'components/auth/auth';

// Styles
import './login.scss';

export default function Login(props) {
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();
    let from = location.state?.from?.pathname || '/';

    const [hasError, setError] = useState(false);

    useEffect(() => {
        if (auth && auth.user) {
            navigate(from, { replace: true });
        }
    }, [auth])  // eslint-disable-line react-hooks/exhaustive-deps

    function handleSubmit(event) {
        event.preventDefault();
        let fd = new FormData(event.currentTarget);
        let user = { username: fd.get('username'), password: fd.get('password') };

        auth.signin(user,
            (success) => {
                navigate(from, { replace: true });
            }, (error) => {
                setError(true);
                console.log('invalid username/password');
            });

    }

    function handleChange(event) {
        event.preventDefault();

        setError(false);
    }


    return (
        <div className="route-login">
            <div className="page">
                <div className="page-content">
                    <div className="content">
                        <Form handleSubmit={handleSubmit} handleChange={handleChange} error={hasError} />
                    </div>
                </div>
            </div>
        </div>
    )
}


function Form({ handleSubmit, handleChange,  error, ...props }) {
    return (
        <form className="form" onSubmit={handleSubmit} onChange={handleChange}>
            <h3> Login </h3>
            <div className="form-group">
                <label htmlFor="username"> Username: </label>
                <input id="username" name="username" type="text" required />
            </div>
            <div className="form-group">
                <label htmlFor="password"> Password: </label>
                <input id="password" name="password" type="password" required />
            </div>

            {error ?
                <div className="error">
                    <p> Invalid username/password</p>
                </div>
                :
            <> </>}

            <button type="submit"> Login </button>
        </form>
    )
}